/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import { jsx, css } from '@emotion/react'
import chroma from 'chroma-js'
import { Download, Filter, Wifi, Globe, Lock, Book, Settings, Server, Users, Copy, MoreHorizontal, ChevronDown, Search, AlertCircle, ArrowUpCircle } from 'feather-icons-react'

import './App.css';

function App() {

    function randomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    Array.prototype.random = function () {
      return this[Math.floor((Math.random()*this.length))];
    }

    function get_random (list) {
      return list[Math.floor((Math.random()*list.length))];
    }


    var randomProperty = function (obj) {
        var keys = Object.keys(obj);
        return obj[keys[ keys.length * Math.random() << 0]];
    };

// Function to find a color with sufficient contrast
function findAccessibleCombo(background, theme, hue = null, contrastThreshold = 4.5) {
  // Helper function to calculate contrast
function calculateContrast(color1, color2) {
  const chromaColor1 = chroma.valid(color1) ? chroma(color1) : null;
  const chromaColor2 = chroma.valid(color2) ? chroma(color2) : null;

  if (!chromaColor1 || !chromaColor2) {
    // Handle invalid color formats gracefully, e.g., return a default color
    return null;
  }

  return chroma.contrast(chromaColor1, chromaColor2);
}

  // Function to find a valid text color
  function findValidTextColor(colors) {
    const validColors = colors.filter((color) => {
      const contrast = calculateContrast(background, color);
      return contrast >= contrastThreshold;
    });

    if (validColors.length > 0) {
      const randomIndex = Math.floor(Math.random() * validColors.length);
      return validColors[randomIndex];
    }

    return null;
  }

  // Function to find a valid border color within 1-4 contrast to background
  function findValidBorderColor(colors) {
    const validColors = colors.filter((color) => {
      const contrast = calculateContrast(background, color);
      return contrast >= 0.1 && contrast <= 1.3;
    });

    if (validColors.length > 0) {
      const randomIndex = Math.floor(Math.random() * validColors.length);
      return validColors[randomIndex];
    }

    return 'hotpink';
  }

  if (hue) {
    // Use the specified hue
    const hueColors = theme[hue] || [];
    const textColor = findValidTextColor(hueColors);
    const borderColor = findValidBorderColor(hueColors);

    return { background, color: textColor, borderColor };
  } else {
    // No hue provided, choose from all hues
    const allColors = Object.values(theme).flat();
    const textColor = findValidTextColor(allColors);
    const borderColor = findValidBorderColor(allColors);

    return { background, color: textColor, borderColor };
  }
}

    const theme2 = {
    gray: [
      "#ffffff",
      "#e8e8e8",
      "#d1d1d1",
      "#bababa",
      "#a3a3a3",
      "#8c8c8c",
      "#757575",
      "#5e5e5e",
      "#464646",
      "#2f2f2f",
      "#171717",
      "#0F0F0F",
      "#020408",
    ],
    "slate-gray": [
      "#09090b",
      "#1d1d22",
      "#313138",
      "#45444f",
      "#595866",
      "#6d6c7d",
      "#838292",
      "#9a9aa7",
      "#b2b1bb",
      "#c9c9d0",
      "#e1e0e4",
      "#f8f8f9",
    ],
    blue: [
      "#eff5fe",
      "#c9ddfa",
      "#a3c6f7",
      "#7daef4",
      "#5797f0",
      "#317fed",
      "#1969db",
      "#1556b4",
      "#10448d",
      "#0c3166",
      "#071e3f",
      "#030b18",
    ],
    indigo: [
      "#080614",
      "#19143f",
      "#2a216a",
      "#3a2e95",
      "#4b3bc0",
      "#5c49eb",
      "#725fff",
      "#8c7cff",
      "#a69aff",
      "#c0b7ff",
      "#dad5ff",
      "#f4f3ff",
    ],
    violet: [
      "#110517",
      "#2e0d3e",
      "#4b1465",
      "#681c8c",
      "#8424b3",
      "#a12cda",
      "#b543ec",
      "#c366f0",
      "#d089f3",
      "#deabf7",
      "#eccefa",
      "#f9f0fe",
    ],
    magenta: [
      "#13040f",
      "#350a2c",
      "#581148",
      "#7b1765",
      "#9e1e82",
      "#c1249e",
      "#d43cb2",
      "#dc61c1",
      "#e485cf",
      "#eca9dd",
      "#f4ceec",
      "#fcf2fa",
    ],
    red: [
      "#fdeef1",
      "#f9c7d1",
      "#f49fb0",
      "#f07790",
      "#ec506f",
      "#e7284e",
      "#d31138",
      "#ac0e2e",
      "#860b24",
      "#5f0819",
      "#39050f",
      "#120205",
    ],
    "red-orange": [
      "#190b05",
      "#3d1307",
      "#601b0a",
      "#84240c",
      "#a72c0e",
      "#cb3411",
      "#ee3c13",
      "#fe562f",
      "#fe7d5e",
      "#ffa48e",
      "#ffccbe",
      "#fff3ee",
    ],
    orange: [
      "#fff5ed",
      "#ffdab7",
      "#ffc081",
      "#ffa54a",
      "#ff8a14",
      "#d37413",
      "#a65d13",
      "#794714",
      "#603910",
      "#472a0c",
      "#2e1c08",
      "#150d04",
    ],
    gold: [
      "#140e05",
      "#2c1f0a",
      "#432f0e",
      "#5b4013",
      "#725118",
      "#946818",
      "#d4950d",
      "#eeb028",
      "#f2c259",
      "#f7d38a",
      "#fbe5bc",
      "#fff5e9",
    ],
    yellow: [
      "#fff5ed",
      "#ffdab7",
      "#ffc081",
      "#ffa54a",
      "#ff8a14",
      "#d37413",
      "#a65d13",
      "#794714",
      "#603910",
      "#472a0c",
      "#2e1c08",
      "#150d04",
    ],
    green: [
      "#eafeea",
      "#bdf3cc",
      "#90e8ae",
      "#63dd90",
      "#35d272",
      "#26b056",
      "#25833a",
      "#21662b",
      "#1b5122",
      "#153c1a",
      "#0f2611",
      "#091108",
    ],
    teal: [
      "#07100c",
      "#0c231a",
      "#113628",
      "#164936",
      "#1b5b45",
      "#177f63",
      "#12a282",
      "#2abb9b",
      "#5acab1",
      "#8bdac7",
      "#bbe9dc",
      "#ecf8f2",
    ],
    cyan: [
      "#0a1211",
      "#102425",
      "#153639",
      "#1b484c",
      "#215a60",
      "#247981",
      "#24a9b6",
      "#28d6e6",
      "#57e0ec",
      "#85eaf2",
      "#b4f5f9",
      "#e2ffff",
    ],
    }
  

  const theme1 = {
     gray: [
         "rgb(255, 255, 255)",//0
         "rgb(250, 249, 248)",//1
         "rgb(249, 247, 246)",//2
         "rgb(247, 245, 244)",//3
         "rgb(238, 235, 234)",//4
         "rgb(218, 214, 213)",//5
         "rgb(175, 172, 171)",//6
         "rgb(112, 110, 109)",//7
         "rgb(68, 67, 66)",//8
         "rgb(46, 45, 45)",//9
         "rgb(35, 34, 34)",//10
         "rgb(31, 30, 30)",//11
         "rgb(24, 23, 23)",//12
         "rgb(18, 17, 17)",//13
     ],
     red: [
        "rgb(255, 246, 244)",
        "rgb(255, 211, 207)",
        "rgb(255, 177, 171)",
        "rgb(246, 143, 135)",
        "rgb(228, 108, 99)",
        "rgb(208, 72, 65)",
        "rgb(178, 45, 48)",
        "rgb(148, 8, 33)",
        "rgb(118, 0, 18)",
        "rgb(90, 0, 0)",
        "rgb(66, 0, 0)",
     ],
     yellow: [
        "rgb(252, 249, 233)",
        "rgb(248, 229, 185)",
        "rgb(239, 192, 120)",
        "rgb(229, 153, 62)",
        "rgb(217, 121, 23)",
        "rgb(187, 85, 4)",
        "rgb(152, 55, 5)",
        "rgb(118, 43, 11)",
        "rgb(87, 31, 13)",
        "rgb(58, 22, 7)",
        "rgb(58, 22, 7)",
     ],
     orange: [
         "rgb(255, 250, 238)",
         "rgb(254, 227, 192)",
         "rgb(248, 184, 134)",
         "rgb(245, 146, 94)",
         "rgb(229, 111, 74)",
         "rgb(196, 76, 52)",
         "rgb(158, 47, 40)",
         "rgb(126, 30, 35)",
         "rgb(93, 22, 27)",
         "rgb(66, 14, 17)",
         "rgb(66, 14, 17)",
     ],
     green: [
         "rgb(239, 255, 237)",
         "rgb(203, 244, 201)",
         "rgb(133, 217, 150)",
         "rgb(51, 194, 127)",
         "rgb(30, 166, 114)",
         "rgb(9, 130, 93)",
         "rgb(14, 98, 69)",
         "rgb(13, 75, 59)",
         "rgb(11, 55, 51)",
         "rgb(8, 36, 41)",
         "rgb(8, 36, 41)",
     ],
     blue: [
     "rgb(240, 245, 255)",
     "rgb(206, 222, 253)",
     "rgb(173, 199, 252)",
     "rgb(133, 170, 245)",
     "rgb(108, 148, 236)",
     "rgb(90, 130, 222)",
     "rgb(75, 112, 204)",
     "rgb(63, 93, 179)",
     "rgb(50, 73, 148)",
     "rgb(37, 53, 112)",
     "rgb(25, 34, 74)",
     ]

  }


  const [count, setCount] = useState(0);

    const durableObjectName = 'COUNTER'; // Replace with the actual name of your Durable Object

    const fetchCount = async () => {
        try {
            const response = await fetch(`https://ts-gen-count.adam-f8f.workers.dev/?name=${durableObjectName}`);
            const data = await response.text();
            setCount(data);
        } catch (error) {
            console.error('Error fetching count:', error);
        }
    };

    const handleIncrement = async () => {
        try {
            await fetch(`https://ts-gen-count.adam-f8f.workers.dev/increment?name=${durableObjectName}`, {
                method: 'POST',
            });
            fetchCount(); // Update count after increment
        } catch (error) {
            console.error('Error incrementing count:', error);
        }
    };


    useEffect(() => {
        fetchCount();
    }, []);

  const [theme, setTheme] = useState(theme1)

  const appTheme = [
      {
          color: theme.gray[5],
          background: theme.gray[10],
          headerBackgroundColor: theme.gray[11],
          headerBorderColor: theme.gray[9],
      },
      {
          color: theme.gray[5],
          background: theme.gray[11],
          headerBackgroundColor: theme.gray[12],
          headerBorderColor: theme.gray[10],
      },
      {
          color: theme.gray[6],
          background: theme.gray[12],
          headerBackgroundColor: theme.gray[13],
          headerBorderColor: theme.gray[11],
      },
      {
          color: theme.gray[5],
          background: theme.gray[11],
          headerBackgroundColor: theme.gray[10],
          headerBorderColor: theme.gray[9],
      },
      {
          color: theme.gray[5],
          background: theme.gray[12],
          headerBackgroundColor: theme.gray[11],
          headerBorderColor: theme.gray[10],
      },
      {
          color: theme.gray[6],
          background: theme.gray[13],
          headerBackgroundColor: theme.gray[12],
          headerBorderColor: theme.gray[11],
      },
  ]

  const [appThemeInt, setAppThemeInt] = useState(randomInt(0,2))

  const [themeColor, setThemeColor] = useState(randomProperty(theme).random())
  const [iconOpacity, setIconOpacity] = useState(randomInt(50,100) / 100)
  const [iconWarning, setIconWarning] = useState(theme.yellow[randomInt(4,10)])
  const [iconError, setIconError] = useState(theme.red[randomInt(4,10)])

  const [backgroundInt, setBackgroundInt] = useState(randomInt(10,12))
  const [headerBackgroundInt, setHeaderBackgroundInt] = useState(backgroundInt+1)

  const [app, setApp] = useState(findAccessibleCombo(theme.gray[backgroundInt], theme, 'gray'))
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState(findAccessibleCombo(theme.gray[headerBackgroundInt], theme, 'blue'))
  const [headerLinkHover, setHeaderLinkHover] = useState(appTheme[appThemeInt].background)
  const [upgradeArrow, setUpgradeArrow] = useState(theme.red.random())
const [tableBorder, setTableBorder] = useState(
    theme.gray[backgroundInt-2]
)

  const [headerBorderColor, setHeaderBorderColor] = useState(theme.gray[backgroundInt-1])
  const [headerLinkActive, setHeaderLinkActive] = useState(theme.blue[randomInt(3,7)])
  const [textInput, setTextInput] = useState(findAccessibleCombo(theme.gray[randomInt(10,12)], theme, 'gray'))
  const [avatar, setAvatar] = useState(findAccessibleCombo(randomProperty(theme).random(), theme))

  const [tagBase, setTagBase] = useState(findAccessibleCombo(theme.gray.random(), theme, 'gray'))
  const [tagPrimary, setTagPrimary] = useState(findAccessibleCombo(theme.blue.random(), theme, 'blue'))
  const [tagSuccess, setTagSuccess] = useState(findAccessibleCombo(theme.green.random(), theme, 'green'))
  const [tagWarning, setTagWarning] = useState(findAccessibleCombo(theme.yellow[randomInt(3,10)], theme, 'yellow'))
  const [tagOutline, setTagOutline] = useState(findAccessibleCombo(theme.gray[randomInt(5,6)], theme, 'gray'))
  const [tagError, setTagError] = useState(findAccessibleCombo(theme.red.random(), theme, 'red'))
  const [tagBeta, setTagBeta] = useState(findAccessibleCombo(theme.yellow[randomInt(7,theme.yellow.length-1)], theme, 'yellow'))

  const [noteBase, setNoteBase] = useState(findAccessibleCombo(theme.gray.random(), theme, 'gray'))
  const [notePrimary, setNotePrimary] = useState(findAccessibleCombo(theme.blue.random(), theme, 'blue'))
  const [noteError, setNoteError] = useState(findAccessibleCombo(theme.green.random(), theme, 'green'))
  const [noteWarning, setNoteWarning] = useState(findAccessibleCombo(theme.yellow[randomInt(3,10)], theme, 'yellow'))


const [buttonSecondary, setButtonSecondary] = useState(
    {
        background: theme.gray[backgroundInt-1],
        color: theme.gray[2],
        borderColor: theme.gray[backgroundInt-2],
        backgroundHover: theme.gray[backgroundInt],
        colorHover: theme.gray[1],
        borderColorHover: theme.gray[backgroundInt-1],
    }
)
//const [buttonPrimary, setButtonPrimary] = useState(findAccessibleCombo(theme.blue[randomInt(7,10)], theme, 'blue'))
const [buttonPrimary, setButtonPrimary] = useState(
  {
      background: backgroundInt < 11? theme.blue[backgroundInt-1] : theme.blue[7],
      color: theme.blue[1],
      borderColor: backgroundInt < 11? theme.blue[backgroundInt] : theme.blue[8],
      backgroundHover: backgroundInt < 11? theme.blue[backgroundInt] : theme.blue[9],
      colorHover: theme.blue[0],
      borderColorHover: backgroundInt < 10? theme.blue[backgroundInt+1] : theme.blue[9],
  }
)

  const [buttonPrimaryMinimal, setButtonPrimaryMinimal] = useState(findAccessibleCombo(theme.gray[backgroundInt], theme, 'blue'))
  const [buttonSuccess, setButtonSuccess] = useState(findAccessibleCombo(theme.green.random(), theme, 'green'))
  const [buttonWarning, setButtonWarning] = useState(findAccessibleCombo(theme.yellow[randomInt(7,10)], theme, 'yellow'))
//const [buttonError, setButtonError] = useState(findAccessibleCombo(theme.red[randomInt(7,10)], theme, 'red'))
    const [buttonError, setButtonError] = useState(
  {
      background: backgroundInt < 11? theme.red[backgroundInt-1] : theme.red[7],
      color: theme.red[1],
      borderColor: backgroundInt < 11? theme.red[backgroundInt] : theme.red[8],
      backgroundHover: backgroundInt < 11? theme.red[backgroundInt] : theme.red[9],
      colorHover: theme.red[0],
      borderColorHover: backgroundInt < 10? theme.red[backgroundInt+1] : theme.red[9],
  }
    )

  const [menuList, setMenuList] = useState(findAccessibleCombo(theme.gray[randomInt(backgroundInt -1,backgroundInt)], theme, 'gray'))

  const [card, setCard] = useState(findAccessibleCombo(theme.gray[randomInt(backgroundInt-2,13)], theme, 'gray'))
  const [tableRowBackgroundHover, setTableRowBackgroundHover] = useState([
        theme.gray[backgroundInt -1],
        theme.gray[backgroundInt -2],
        theme.gray[backgroundInt -3],
        ...(backgroundInt < 12? theme.gray[backgroundInt + 1] : []),
        ...(backgroundInt < 11? theme.gray[backgroundInt + 2] : []),
        ...(backgroundInt < 10? theme.gray[backgroundInt + 3] : []),
  ].random())

  const updateAll = () => {

      handleIncrement()
      setBackgroundInt(randomInt(10,12))
      setHeaderBackgroundInt(backgroundInt+1)
      //setHeaderBackgroundInt([
      //    ...(backgroundInt < 12? [backgroundInt -1] : []), 
      //    ...(backgroundInt < 12? [backgroundInt -2] : []), 
      //    ...(backgroundInt < 12? [backgroundInt + 1] : []),
      //    ...(backgroundInt < 12? [backgroundInt + 2] : []),
      //].random()
      //)

      //setHeaderBackgroundColor(findAccessibleCombo(theme.gray[backgroundInt-1], theme, 'blue'))
      //setHeaderBackgroundColor(findAccessibleCombo(theme.gray[11], theme, 'blue'))
      setHeaderBackgroundColor(findAccessibleCombo(theme.gray[headerBackgroundInt], theme, 'blue'))
      setHeaderBorderColor(theme.gray[ backgroundInt-1 ])
      setHeaderLinkHover(appTheme[appThemeInt].background)

      setAvatar(findAccessibleCombo(theme.blue.random(), theme, 'gray'))
      setApp(findAccessibleCombo(theme.gray[backgroundInt], theme, 'gray'))
      setAppThemeInt(randomInt(0,5))
      //setTextInput(findAccessibleCombo(theme.gray[randomInt(10,13)], theme, 'gray'))
      setTextInput(findAccessibleCombo(theme.gray[backgroundInt-1], theme, 'gray'))

      setTagBase(findAccessibleCombo(theme.gray[randomInt(7,12)], theme, 'gray'))
      setTagPrimary(findAccessibleCombo(theme.blue[randomInt(5,10)], theme, 'blue'))
      setTagSuccess(findAccessibleCombo(theme.green[randomInt(5,10)], theme, 'green'))
      setTagWarning(findAccessibleCombo(theme.yellow[randomInt(5,10)], theme, 'yellow'))
      setTagError(findAccessibleCombo(theme.red[randomInt(5,10)], theme, 'red'))
      setTagBeta(findAccessibleCombo(theme.yellow[randomInt(5,10)], theme, 'yellow'))

      setNoteBase(findAccessibleCombo(theme.gray[randomInt(7,12)], theme, 'gray'))
      setNotePrimary(findAccessibleCombo(theme.blue[randomInt(5,10)], theme, 'blue'))
      setNoteWarning(findAccessibleCombo(theme.yellow[randomInt(5,10)], theme, 'yellow'))
      setNoteError(findAccessibleCombo(theme.red[randomInt(5,10)], theme, 'red'))

      //setButtonSecondary(findAccessibleCombo(theme.gray[randomInt(7,10)], theme, 'gray'))
      setButtonSecondary(
        {
            background: theme.gray[backgroundInt-1],
            color: theme.gray[2],
            borderColor: theme.gray[backgroundInt-2],
            backgroundHover: theme.gray[backgroundInt],
            colorHover: theme.gray[1],
            borderColorHover: theme.gray[backgroundInt-1],
        }
      )
      //setButtonPrimary(findAccessibleCombo(theme.blue[randomInt(7,10)], theme, 'blue'))
      setButtonPrimary(
          {
              background: backgroundInt < 11? theme.blue[backgroundInt-1] : theme.blue[7],
              color: theme.blue[1],
              borderColor: backgroundInt < 11? theme.blue[backgroundInt] : theme.blue[8],
              backgroundHover: backgroundInt < 11? theme.blue[backgroundInt] : theme.blue[8],
              colorHover: theme.blue[2],
              borderColorHover: backgroundInt < 10? theme.blue[backgroundInt+1] : theme.blue[9],
          }
      )
      //setButtonWarning(findAccessibleCombo(theme.yellow[randomInt(6,10)], theme, 'yellow'))
      setButtonWarning(
      {
          background: backgroundInt < 11? theme.yellow[backgroundInt-2] : theme.yellow[6],
          color: theme.yellow[1],
          borderColor: backgroundInt < 11? theme.yellow[backgroundInt-1] : theme.yellow[7],
          backgroundHover: backgroundInt < 11? theme.yellow[backgroundInt-1] : theme.yellow[7],
          colorHover: theme.yellow[0],
          borderColorHover: backgroundInt < 10? theme.yellow[backgroundInt+1] : theme.yellow[8],
      }
      )
      setButtonError(
  {
      background: backgroundInt < 11? theme.red[backgroundInt-1] : theme.red[6],
      color: theme.red[1],
      borderColor: backgroundInt < 11? theme.red[backgroundInt] : theme.red[7],
      backgroundHover: backgroundInt < 11? theme.red[backgroundInt] : theme.red[7],
      colorHover: theme.red[0],
      borderColorHover: backgroundInt < 10? theme.red[backgroundInt+1] : theme.red[9],
  }
      )
      setButtonPrimaryMinimal(findAccessibleCombo(theme.gray[backgroundInt], theme, 'blue'))
      setButtonSuccess(findAccessibleCombo(theme.green[randomInt(6,10)], theme, 'green'))


      setCard(findAccessibleCombo(theme.gray[randomInt(9,12)], theme, 'gray'))
      setMenuList(findAccessibleCombo(theme.gray[randomInt(backgroundInt-1,backgroundInt)], theme, 'gray'))


      setHeaderLinkActive(theme.blue[randomInt(3,7)])

      setThemeColor(randomProperty(theme).random())

      setTableRowBackgroundHover([
        theme.gray[backgroundInt -1],
        theme.gray[backgroundInt -2],
        theme.gray[backgroundInt -3],
        ...(backgroundInt < 11? theme.gray[backgroundInt + 1] : []),
        ...(backgroundInt < 10? theme.gray[backgroundInt + 2] : []),
        ...(backgroundInt < 9? theme.gray[backgroundInt + 3] : []),
  ].random())

  }

const optionA = () => {

      handleIncrement()
      setAppThemeInt(2)
      setUpgradeArrow(theme.red[6])
      setHeaderLinkHover(theme.gray[10])
      setTableBorder(theme.gray[11])

      setAvatar(
          {
              color: theme.blue[2],
              background: theme.blue[6],
              borderColor: theme.blue[7],
          }
      )
      //setTextInput(findAccessibleCombo(theme.gray[randomInt(10,13)], theme, 'gray'))
        setTextInput(
            {
                background: theme.gray[11],
                color: theme.gray[3],
                borderColor: theme.gray[9],
            }
        )

    setTagBase(
        {
            background: theme.gray[8],
            color: theme.gray[4],
            borderColor: theme.gray[8],
        }
    )

        setTagPrimary({
            background: theme.blue[9],
            color: theme.blue[3],
            borderColor: theme.blue[9],
        })
    setTagSuccess(
        {
            background: theme.green[8],
            color: theme.green[2],
            borderColor: theme.green[8],
        }
    )
    setTagWarning(
        {
            background: theme.yellow[8],
            color: theme.yellow[3],
            borderColor: theme.yellow[8],
        }
    )
    setTagError(
            {
                color: theme.red[3],
                background: theme.red[9],
                borderColor: theme.red[8],
            }

    )
        setTagBeta(
            {
                color: theme.yellow[4],
                background: theme.yellow[9],
                borderColor: theme.yellow[8],
            }
        )
    setTagOutline(
        {
            background: 'transparent',
            color: theme.gray[6],
            borderColor: theme.gray[9],
        }
    )

    setNoteBase(
        {
            background: theme.gray[10],
            color: theme.gray[5],
            borderColor: theme.gray[9],
        }
     )
    setNotePrimary(

        {
            background: theme.blue[10],
            color: theme.blue[3],
            borderColor: theme.blue[9],
        }
    )
    setNoteWarning(
        {
            background: theme.yellow[10],
            color: theme.yellow[3],
            borderColor: theme.yellow[8],
        }
    )
    setNoteError(
        {
            background: theme.red[10],
            color: theme.red[3],
            borderColor: theme.red[9],
        }
    )


      //setButtonSecondary(findAccessibleCombo(theme.gray[randomInt(7,10)], theme, 'gray'))
      setButtonSecondary(
        {
            background: theme.gray[11],
            color: theme.gray[2],
            borderColor: theme.gray[9],
            backgroundHover: theme.gray[10],
            colorHover: theme.gray[1],
            borderColorHover: theme.gray[backgroundInt-1],
        }
      )
      //setButtonPrimary(findAccessibleCombo(theme.blue[randomInt(7,10)], theme, 'blue'))
      setButtonPrimary(
          {
              background: backgroundInt < 11? theme.blue[backgroundInt-1] : theme.blue[7],
              color: theme.blue[1],
              borderColor: backgroundInt < 11? theme.blue[backgroundInt] : theme.blue[8],
              backgroundHover: backgroundInt < 11? theme.blue[backgroundInt] : theme.blue[8],
              colorHover: theme.blue[2],
              borderColorHover: backgroundInt < 10? theme.blue[backgroundInt+1] : theme.blue[9],
          }
      )
      //setButtonWarning(findAccessibleCombo(theme.yellow[randomInt(6,10)], theme, 'yellow'))
      setButtonWarning(
      {
          background: backgroundInt < 11? theme.yellow[backgroundInt-2] : theme.yellow[6],
          color: theme.yellow[1],
          borderColor: backgroundInt < 11? theme.yellow[backgroundInt-1] : theme.yellow[7],
          backgroundHover: backgroundInt < 11? theme.yellow[backgroundInt-1] : theme.yellow[7],
          colorHover: theme.yellow[0],
          borderColorHover: backgroundInt < 10? theme.yellow[backgroundInt+1] : theme.yellow[8],
      }
      )
      setButtonError(
  {
      background: backgroundInt < 11? theme.red[backgroundInt-1] : theme.red[6],
      color: theme.red[1],
      borderColor: backgroundInt < 11? theme.red[backgroundInt] : theme.red[7],
      backgroundHover: backgroundInt < 11? theme.red[backgroundInt] : theme.red[7],
      colorHover: theme.red[0],
      borderColorHover: backgroundInt < 10? theme.red[backgroundInt+1] : theme.red[9],
  }
      )
      setButtonPrimaryMinimal(findAccessibleCombo(theme.gray[backgroundInt], theme, 'blue'))
      setButtonSuccess(findAccessibleCombo(theme.green[randomInt(6,10)], theme, 'green'))


    setCard(
        {
           background:theme.gray[11],
            color: theme.gray[5],
            borderColor:theme.gray[10],
        }
    )
    setMenuList(
        {
           background:theme.gray[12],
            color: 'inherit',
            borderColor:theme.gray[9],
        }
    )


      setHeaderLinkActive(theme.blue[randomInt(3,7)])

      setThemeColor(randomProperty(theme).random())

      setTableRowBackgroundHover(theme.gray[11])

}

const optionB = () => {

      handleIncrement()
      setAppThemeInt(1)
      setUpgradeArrow(theme.red[5])
      setHeaderLinkHover(theme.gray[12])
      setTableBorder(theme.gray[9])

      setAvatar(
          {
              color: theme.blue[2],
              background: theme.blue[6],
              borderColor: theme.blue[7],
          }
      )
      //setTextInput(findAccessibleCombo(theme.gray[randomInt(10,13)], theme, 'gray'))
    setTextInput(
        {
            background: theme.gray[10],
            color: theme.gray[4],
            borderColor: theme.gray[9],
        }
    )
    setTagBase(
        {
            background: theme.gray[8],
            color: theme.gray[4],
            borderColor: theme.gray[8],
        }
    )

        setTagPrimary({
            background: theme.blue[9],
            color: theme.blue[3],
            borderColor: theme.blue[9],
        })
    setTagSuccess(
        {
            background: theme.green[8],
            color: theme.green[2],
            borderColor: theme.green[8],
        }
    )
    setTagWarning(
        {
            background: theme.yellow[8],
            color: theme.yellow[3],
            borderColor: theme.yellow[8],
        }
    )
    setTagError(
            {
                color: theme.red[3],
                background: theme.red[9],
                borderColor: theme.red[8],
            }

    )
        setTagBeta(
            {
                color: theme.yellow[3],
                background: theme.yellow[8],
                borderColor: theme.yellow[8],
            }
        )
    setNoteBase(
        {
            background: theme.gray[9],
            color: theme.gray[4],
            borderColor: theme.gray[8],
        }
     )
    setNotePrimary(

        {
            background: theme.blue[9],
            color: theme.blue[3],
            borderColor: theme.blue[8],
        }
    )
    setNoteWarning(
        {
            background: theme.yellow[9],
            color: theme.yellow[3],
            borderColor: theme.yellow[8],
        }
    )
    setNoteError(
        {
            background: theme.red[9],
            color: theme.red[3],
            borderColor: theme.red[8],
        }
    )

      //setButtonSecondary(findAccessibleCombo(theme.gray[randomInt(7,10)], theme, 'gray'))
      setButtonSecondary(
        {
            background: theme.gray[10],
            color: theme.gray[4],
            borderColor: theme.gray[9],
            backgroundHover: theme.gray[9],
            colorHover: theme.gray[1],
            borderColorHover: theme.gray[8],
        }
      )
      //setButtonPrimary(findAccessibleCombo(theme.blue[randomInt(7,10)], theme, 'blue'))
      setButtonPrimary(
          {
              background: backgroundInt < 11? theme.blue[backgroundInt-1] : theme.blue[7],
              color: theme.blue[1],
              borderColor: backgroundInt < 11? theme.blue[backgroundInt] : theme.blue[8],
              backgroundHover: backgroundInt < 11? theme.blue[backgroundInt] : theme.blue[8],
              colorHover: theme.blue[2],
              borderColorHover: backgroundInt < 10? theme.blue[backgroundInt+1] : theme.blue[9],
          }
      )
      //setButtonWarning(findAccessibleCombo(theme.yellow[randomInt(6,10)], theme, 'yellow'))
      setButtonWarning(
      {
          background: backgroundInt < 11? theme.yellow[backgroundInt-2] : theme.yellow[6],
          color: theme.yellow[1],
          borderColor: backgroundInt < 11? theme.yellow[backgroundInt-1] : theme.yellow[7],
          backgroundHover: backgroundInt < 11? theme.yellow[backgroundInt-1] : theme.yellow[7],
          colorHover: theme.yellow[0],
          borderColorHover: backgroundInt < 10? theme.yellow[backgroundInt+1] : theme.yellow[8],
      }
      )
      setButtonError(
  {
      background: backgroundInt < 11? theme.red[backgroundInt-1] : theme.red[6],
      color: theme.red[1],
      borderColor: backgroundInt < 11? theme.red[backgroundInt] : theme.red[7],
      backgroundHover: backgroundInt < 11? theme.red[backgroundInt] : theme.red[7],
      colorHover: theme.red[0],
      borderColorHover: backgroundInt < 10? theme.red[backgroundInt+1] : theme.red[9],
  }
      )
      setButtonPrimaryMinimal(findAccessibleCombo(theme.gray[backgroundInt], theme, 'blue'))
      setButtonSuccess(findAccessibleCombo(theme.green[randomInt(6,10)], theme, 'green'))


    setCard(
        {
           background:theme.gray[10],
           color: theme.gray[4],
           borderColor:theme.gray[9],
        }
    )
    setMenuList(
        {
           background:theme.gray[10],
            color:theme.gray[3],
            borderColor:theme.gray[9],
        }
    )


      setHeaderLinkActive(theme.blue[randomInt(3,7)])

      setThemeColor(randomProperty(theme).random())

      setTableRowBackgroundHover(theme.gray[10])

}

const optionC = () => {

      handleIncrement()
      setAppThemeInt(0)
      setUpgradeArrow(theme.red[5])
      setHeaderLinkHover(theme.gray[12])
      setTableBorder(theme.gray[9])

      setAvatar(
        {
            color: theme.blue[2],
            background: theme.blue[6],
            borderColor: theme.blue[7],
        }
      )

      //setTextInput(findAccessibleCombo(theme.gray[randomInt(10,13)], theme, 'gray'))
     
    setTextInput(
        {
            background: theme.gray[9],
            color: theme.gray[2],
            borderColor: theme.gray[8],
        }
    )

    setTagBase(
        {
            background: theme.gray[8],
            color: theme.gray[4],
            borderColor: theme.gray[8],
        }
    )

        setTagPrimary({
            background: theme.blue[9],
            color: theme.blue[3],
            borderColor: theme.blue[9],
        })
    setTagSuccess(
        {
            background: theme.green[8],
            color: theme.green[2],
            borderColor: theme.green[8],
        }
    )
    setTagWarning(
        {
            background: theme.yellow[8],
            color: theme.yellow[3],
            borderColor: theme.yellow[8],
        }
    )
    setTagError(
            {
                color: theme.red[3],
                background: theme.red[9],
                borderColor: theme.red[8],
            }

    )
        setTagBeta(
            {
                color: theme.yellow[4],
                background: theme.yellow[9],
                borderColor: theme.yellow[8],
            })

    setNoteBase(
        {
            background: 'transparent',
            color: theme.gray[5],
            borderColor: theme.gray[7],
        }
     )
    setNotePrimary(

        {
            background: 'transparent',
            color: theme.blue[4],
            borderColor: theme.blue[8],
        }
    )
    setNoteWarning(
        {
            background: 'transparent',
            color: theme.yellow[4],
            borderColor: theme.yellow[7],
        }
    )
    setNoteError(
        {
            background: 'transparent',
            color: theme.red[4],
            borderColor: theme.red[7],
        }
    )
      //setButtonSecondary(findAccessibleCombo(theme.gray[randomInt(7,10)], theme, 'gray'))
      setButtonSecondary(
        {
            background: theme.gray[9],
            color: theme.gray[2],
            borderColor: theme.gray[8],
            backgroundHover: theme.gray[10],
            colorHover: theme.gray[0],
            borderColorHover: theme.gray[7],
        }
      )
      //setButtonPrimary(findAccessibleCombo(theme.blue[randomInt(7,10)], theme, 'blue'))
      setButtonPrimary(
          {
              background: backgroundInt < 11? theme.blue[backgroundInt-1] : theme.blue[7],
              color: theme.blue[1],
              borderColor: backgroundInt < 11? theme.blue[backgroundInt] : theme.blue[8],
              backgroundHover: backgroundInt < 11? theme.blue[backgroundInt] : theme.blue[8],
              colorHover: theme.blue[2],
              borderColorHover: backgroundInt < 10? theme.blue[backgroundInt+1] : theme.blue[9],
          }
      )
      //setButtonWarning(findAccessibleCombo(theme.yellow[randomInt(6,10)], theme, 'yellow'))
      setButtonWarning(
      {
          background: backgroundInt < 11? theme.yellow[backgroundInt-2] : theme.yellow[6],
          color: theme.yellow[1],
          borderColor: backgroundInt < 11? theme.yellow[backgroundInt-1] : theme.yellow[7],
          backgroundHover: backgroundInt < 11? theme.yellow[backgroundInt-1] : theme.yellow[7],
          colorHover: theme.yellow[0],
          borderColorHover: backgroundInt < 10? theme.yellow[backgroundInt+1] : theme.yellow[8],
      }
      )
      setButtonError(
  {
      background: backgroundInt < 11? theme.red[backgroundInt-1] : theme.red[6],
      color: theme.red[1],
      borderColor: backgroundInt < 11? theme.red[backgroundInt] : theme.red[7],
      backgroundHover: backgroundInt < 11? theme.red[backgroundInt] : theme.red[7],
      colorHover: theme.red[0],
      borderColorHover: backgroundInt < 10? theme.red[backgroundInt+1] : theme.red[9],
  }
      )
      setButtonPrimaryMinimal(findAccessibleCombo(theme.gray[backgroundInt], theme, 'blue'))
      setButtonSuccess(findAccessibleCombo(theme.green[randomInt(6,10)], theme, 'green'))


    setCard(
        {
           background:theme.gray[9],
           color: theme.gray[4],
           borderColor:theme.gray[8],
        }
    )
    setMenuList(
        {
           background:theme.gray[10],
            color:theme.gray[4],
            borderColor:theme.gray[9],
        }
    )


      setHeaderLinkActive(theme.blue[randomInt(3,7)])

      setThemeColor(randomProperty(theme).random())

      setTableRowBackgroundHover(theme.gray[9])

}

  document.onkeydown = checkKey;
    function checkKey(e) {
        e = e || window.event;

        if (e.keyCode == '39') { // Right arrow key
            updateAll();
        }
        else if (e.keyCode == '65') { // 'A' key
            optionA();
        }
        else if (e.keyCode == '66') { // 'B' key
            optionB();
        }
        else if (e.keyCode == '67') { // 'C' key
            optionC();
        }
    }

  const randomAll = () => {
      handleIncrement()
      setThemeColor(randomProperty(theme).random())
      setApp(findAccessibleCombo(randomProperty(theme).random(), theme))
      setHeaderBackgroundColor(randomProperty(theme).random())
      setHeaderBorderColor(findAccessibleCombo(randomProperty(theme).random(), theme))
      setTextInput(findAccessibleCombo(randomProperty(theme).random(), theme))
      setAvatar(findAccessibleCombo(randomProperty(theme).random(), theme))
      setTagBase(findAccessibleCombo(randomProperty(theme).random(), theme))
      setTagPrimary(findAccessibleCombo(randomProperty(theme).random(), theme))
      setTagSuccess(findAccessibleCombo(randomProperty(theme).random(), theme))
      setTagWarning(findAccessibleCombo(randomProperty(theme).random(), theme))
      setTagError(findAccessibleCombo(randomProperty(theme).random(), theme))
      setButtonPrimary(findAccessibleCombo(randomProperty(theme).random(), theme))
      setButtonPrimaryMinimal(findAccessibleCombo(app.backgroundColor, theme))
      setButtonSecondary(findAccessibleCombo(randomProperty(theme).random(), theme))
      setButtonWarning(findAccessibleCombo(randomProperty(theme).random(), theme))
      setButtonError(findAccessibleCombo(randomProperty(theme).random(), theme))

      setCard(findAccessibleCombo(randomProperty(theme).random(), theme))
      setMenuList(findAccessibleCombo(randomProperty(theme).random(), theme))

      setIconWarning(theme.yellow.random())
      setIconError(theme.red.random())

  }

  return (
      <div css={{
          minHeight: '100dvh',
          backgroundColor: appTheme[appThemeInt].background,
          color: appTheme[appThemeInt].color,
          overflowX: 'hidden', // so that page doesn't scroll on right arrow
      }}>
      <header css={{ 
          padding: '12px 12px 0 12px',
          backgroundColor: appTheme[appThemeInt].headerBackgroundColor,
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: appTheme[appThemeInt].headerBorderColor,
      }}>
      <div css={{display: 'flex', alignItems: 'center' }}>
          <svg onClick={() => updateAll()} width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" css={{ '&:hover': {color: themeColor,},  marginRight: '16px'}}>
              <circle opacity="0.2" cx="3.4" cy="3.25" r="2.7" fill="currentColor"></circle>
              <circle cx="3.4" cy="11.3" r="2.7" fill="currentColor"></circle>
              <circle opacity="0.2" cx="3.4" cy="19.5" r="2.7" fill="currentColor"></circle>
              <circle cx="11.5" cy="11.3" r="2.7" fill="currentColor"></circle>
              <circle cx="11.5" cy="19.5" r="2.7" fill="currentColor"></circle>
              <circle opacity="0.2" cx="11.5" cy="3.25" r="2.7" fill="currentColor"></circle>
              <circle opacity="0.2" cx="19.5" cy="3.25" r="2.7" fill="currentColor"></circle>
              <circle cx="19.5" cy="11.3" r="2.7" fill="currentColor"></circle>
              <circle opacity="0.2" cx="19.5" cy="19.5" r="2.7" fill="currentColor"></circle>
          </svg>
          <b>example.com</b>
          <nav css={{
            marginLeft: 'auto',
            
          }}>
             <a css={{fontSize: '14px', marginRight: '12px'}} href='#0'>Download</a>
             <a css={{fontSize: '14px', marginRight: '12px'}} href='#0'>Support</a>
             <a css={{fontSize: '14px', marginRight: '24px'}} href='#0'>Docs</a>
          </nav>
          <button onClick={() => randomAll()} css={{
                borderRadius: '9999px',
                backgroundColor: avatar.background,
                color: avatar.color,
                textAlign: 'center',
                height: '24px',
                width: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 700,
                fontSize: '12px',
                borderWidth: 0,
          }}>
          A
          </button>
          </div>
          <nav css={{ display: 'flex', paddingTop: '24px', overflow: 'hidden' }}>

              <article css={{ display: 'block', margin: 0 }}>
                  <a href="#0" 
                  css={{ margin: 0, borderRadius: '6px', color: theme.blue[6], textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '8px', fontWeight: 'bold', padding: '6px 18px',  '&:hover': { backgroundColor: headerLinkHover },}}>
                  <Server size={14} opacity={iconOpacity} /><span>Machines</span></a>
                <div css={{ margin: '-8px auto 0 auto', borderBottomWidth: '2px', borderBottomStyle:'solid', borderBottomColor: theme.blue[6], width:'75%' }}> &nbsp;</div>
              </article>

              <article css={{ display: 'block', margin: 0 }}>
              <a href="#0" 
                  css={{ borderRadius: '6px', textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '8px', fontWeight: 'bold', padding: '6px 18px',  '&:hover': { backgroundColor: headerLinkHover },}}>
<svg xmlns="http://www.w3.org/2000/svg" width="1.125em" height="1.125em" viewBox="0 0 24 24" fill="none" stroke="currentColor"><g strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" opacity={iconOpacity}><path d="M8.3 10a.7.7 0 0 1-.626-1.079L11.4 3a.7.7 0 0 1 1.198-.043L16.3 8.9a.7.7 0 0 1-.572 1.1Z"></path><rect x="3" y="14" width="7" height="7" rx="1"></rect><circle cx="17.5" cy="17.5" r="3.5"></circle></g></svg>
                  <span>Apps</span></a>
              </article>
              <article css={{ display: 'block', margin: 0 }}>
              <a href="#0" 
                  css={{ borderRadius: '6px', color: 'currentColor', textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '8px', fontWeight: 'bold', padding: '6px 18px',  '&:hover': { backgroundColor: headerLinkHover }}}>
                  <Wifi size={14} opacity={iconOpacity} /><span>Services</span></a>
              </article>

              <article css={{ display: 'block', margin: 0 }}>
                  <a href="#0" 
                  css={{ borderRadius: '6px', color: 'currentColor', textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '8px', fontWeight: 'bold', padding: '6px 18px',  '&:hover': { backgroundColor: headerLinkHover }}}>
                  <Users size={14} opacity={iconOpacity} /><span>Users</span></a>
              </article>

              <article css={{ display: 'block', margin: 0 }}>
                  <a href="#0" 
                  css={{ borderRadius: '6px', color: 'currentColor', textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '8px', fontWeight: 'bold', padding: '6px 18px',  '&:hover': { backgroundColor: headerLinkHover }}}>

                  <Book size={14} opacity={iconOpacity} /><span>Logs</span></a>
              </article>

              <article css={{ display: 'block', margin: 0 }}>
              <a href="#0" 
                  css={{ whiteSpace: 'nowrap', borderRadius: '6px', color: 'currentColor', textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '8px', fontWeight: 'bold', padding: '6px 18px',  '&:hover': { backgroundColor: headerLinkHover }}}>
              <Lock size={14} opacity={iconOpacity} /><span>Access Controls</span></a>
      </article>

              <article css={{ display: 'block', margin: 0 }}>
                  <a href="#0" 
                  css={{ borderRadius: '6px', color: 'currentColor', textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '8px', fontWeight: 'bold', padding: '6px 18px',  '&:hover': { backgroundColor: headerLinkHover }}}>
                  <Globe size={14} opacity={iconOpacity} /><span>DNS</span></a>
          </article>

              <article css={{ display: 'block', margin: 0 }}>
          <a href="#0" 
                  css={{ borderRadius: '6px', color: 'currentColor', textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '8px', fontWeight: 'bold', padding: '6px 18px',  '&:hover': { backgroundColor: headerLinkHover }}}>
          <Settings size={14} opacity={iconOpacity} /><span>Settings</span></a>
  </article>

          </nav>
      </header>
      <div css={{ display: 'block' }}>
      <section css={{ 
          padding: '32px', color: appTheme[appThemeInt].color, display: 'grid', justifyContent: 'space-between', gap: '32px', gridTemplateColumns: '1fr minmax(256px, 280px)' 
      }}>
          <header>
              <h1 css={{ 
                  margin: '0 0 0 0', 
                  fontSize: '32px', 
                  fontWeight: 600, 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: '8px', 
                  lineHeight: 1
              }}>
                  <span>Dark Mode Theme</span>
          <sup css={{fontSize: '10px', borderRadius: '9999px', padding: '4px 8px', backgroundColor: tagBeta.background, color: tagBeta.color }}>Alpha</sup>
    </h1>
    <h2 css={{margin: '16px 0 0 0', fontSize: '16px', fontWeight: 400, lineHeight:1.25 }}>
        Manage the colors connected to your DOM elements
    </h2>
          <p css={{ lineHeight: 1.5, maxWidth: '80ch', fontSize: '12px', marginTop: '12px' }}>
              Quickly cycle through thousands of accessible combinations of colors pulled directly from your theme and applied to your components and app UI.  
          </p>

<section css={{ display: 'grid', gap: '16px'}}>
    <div css={{ display: 'flex', gap: '8px'}}>

          <div css={{
                  borderRadius: '6px',
                  color: textInput.color,
                  backgroundColor: textInput.background,
                  borderColor: textInput.borderColor,
                  minWidth: '320px',
                  display: 'flex',
                  padding: '8px',
                  borderWidth: '1px',
                  borderStyle: 'solid',
          position:'relative',
          alignItems: 'center',
          }}>
              <Search size={14} strokeWidth={3} color='currentColor' css={{zIndex: 9999}}/>
          <input type='text' 
              defaultValue='Search for the perfect color code'
              css={{
          paddingLeft: '32px',
          position: 'absolute',
          top: 0,left: 0, right: 0, bottom: 0,
                  width: 'auto',
                  WebkitAppearance: 'none',
                  appearance: 'none',
                  borderWidth: '0',
                  borderStyle: 'none',
                  borderRadius: '6px',
                  color: textInput.color,
                  backgroundColor: textInput.background,
                  borderColor: 'transparent',
          background: 'transparent',
          color: 'inherit',
          width: '100%',

              }}
          />
          </div>
          <div>
          <button

          css={{ 
padding: '8px 12px', 
color: buttonSecondary.color, backgroundColor: buttonSecondary.background, borderColor: buttonSecondary.borderColor,
'&:hover': { color: buttonSecondary.colorHover, backgroundColor: buttonSecondary.backgroundHover, borderColor: buttonSecondary.borderColorHover },
          display: 'flex',
          alignItems: 'center', 
          gap: '8px',
          justifyContent: 'center',
          fontSize: '16px'
          }}>
                <Filter size={14} />
                <span>Filters</span>
                <ChevronDown size={20} />
          </button>
      </div>
          <div>
          <button
            
          css={{ 
          padding: '8px 12px',
          width: 'auto',
color: buttonSecondary.color, backgroundColor: buttonSecondary.background, borderColor: buttonSecondary.borderColor,
'&:hover': { color: buttonSecondary.colorHover, backgroundColor: buttonSecondary.backgroundHover, borderColor: buttonSecondary.borderColorHover }
          }}>
              <Download size={16} />
          </button>
          </div>
          <input type='email' 
              defaultValue='firstname.lastname@example.com'
              css={{
                  width: 'auto',
                  minWidth: '240px',
                  WebkitAppearance: 'none',
                  appearance: 'none',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderRadius: '6px',
                  padding: '8px 16px',
                  color: textInput.color,
                  backgroundColor: textInput.background,
                  borderColor: textInput.borderColor,
              }}
          />
          
          <input type='text' 
              defaultValue='84'
              css={{
                  width: '80px',
                  WebkitAppearance: 'none',
                  appearance: 'none',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderRadius: '6px',
                  padding: '8px 16px',
                  color: textInput.color,
                  backgroundColor: textInput.background,
                  borderColor: textInput.borderColor,
                  lineHeight: 1,
              }}
          />
              <button onClick={() => optionA()} css={{ whiteSpace: 'nowrap', marginLeft: 'auto', lineHeight: 1, padding: '8px 24px', 
                color: buttonPrimary.color, backgroundColor: buttonPrimary.background, borderColor: buttonPrimary.borderColor,
                '&:hover': { color: buttonPrimary.colorHover, backgroundColor: buttonPrimary.backgroundHover, borderColor: buttonPrimary.borderColorHover }
                }}>A</button>
              <button onClick={() => optionB()} css={{ whiteSpace: 'nowrap', marginLeft: 'auto', lineHeight: 1, padding: '8px 24px', 
                color: buttonPrimary.color, backgroundColor: buttonPrimary.background, borderColor: buttonPrimary.borderColor,
                '&:hover': { color: buttonPrimary.colorHover, backgroundColor: buttonPrimary.backgroundHover, borderColor: buttonPrimary.borderColorHover }
                }}>B</button>
              <button onClick={() => optionC()} css={{ whiteSpace: 'nowrap', marginLeft: 'auto', lineHeight: 1, padding: '8px 24px', 
                color: buttonPrimary.color, backgroundColor: buttonPrimary.background, borderColor: buttonPrimary.borderColor,
                '&:hover': { color: buttonPrimary.colorHover, backgroundColor: buttonPrimary.backgroundHover, borderColor: buttonPrimary.borderColorHover }
                }}>C</button>
      </div>
          

<section>
          <div>

          <span css={{ marginBottom: '24px', padding: '2px 8px', borderRadius: '9999px', fontSize: '12px', gap: '4px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap', color: tagBase.color, backgroundColor: tagBase.background, }}>6 machines</span>
        <table css={{ width: '100%', color: appTheme[appThemeInt].color }}>
            <thead>
                <tr>
                    <th css={{ borderBottomWidth: '1px', borderBottomStyle: 'solid', borderBottomColor: tableBorder, fontSize: '12px', textAlign: 'left', }}>Machine</th>
                    <th css={{ borderBottomWidth: '1px', borderBottomStyle: 'solid', borderBottomColor: tableBorder, fontSize: '12px', textAlign: 'left', }}>Addresses</th>
                    <th css={{ borderBottomWidth: '1px', borderBottomStyle: 'solid', borderBottomColor: tableBorder, fontSize: '12px', textAlign: 'left', }}>Version</th>
                    <th css={{ borderBottomWidth: '1px', borderBottomStyle: 'solid', borderBottomColor: tableBorder, fontSize: '12px', textAlign: 'left', }}>Last Seen</th>
                    <th css={{ borderBottomWidth: '1px', borderBottomStyle: 'solid', borderBottomColor: tableBorder, fontSize: '12px', textAlign: 'left', }}> </th>
                </tr>
            </thead> 
            <tbody>
                <tr css={{ '&:hover': { backgroundColor: tableRowBackgroundHover } }}>
                    <td css={{
                        borderBottomColor: tableBorder,
                    }}>
                        <div>
                            <p css={{margin: 0, display:'flex', alignItems: 'center', gap: '8px' }}><span css={{ display: 'block', fontSize: '12px', width: '12px', height: '12px', borderRadius: '9999px', backgroundColor: theme.green[5]}}></span><b>adams-iphone</b></p>
                            <p css={{ fontSize: '75%', margin: '4px 0 8px 0',  }}>ptchhtjqt2@privaterelay.appleid.com</p>

          <span css={{ marginRight: '8px', padding: '2px 4px', borderRadius: '4px', fontSize: '12px', gap: '4px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap', color: tagSuccess.color, backgroundColor: tagSuccess.background, }}>SSH<AlertCircle size={12} color='currentColor' opacity={iconOpacity}  /></span>

          <span css={{ padding: '2px 4px', borderRadius: '4px', fontSize: '12px', gap: '4px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap', color: tagPrimary.color, backgroundColor: tagPrimary.background, }}>Exit Node <AlertCircle size={12} color='currentColor' opacity={iconOpacity}  /></span>
                        </div>
                    </td>
                    <td css={{ borderColor: tableBorder, position: 'relative' }}>
                        <div css={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <code>100.81.5.119</code>
                            <ChevronDown size={14} />
                        </div>
                        <div css={{ zIndex: 9999, bottom: '-96px', position: 'absolute', borderRadius: '6px', color: menuList.color, backgroundColor: menuList.background, 
                        boxShadow:  '0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07)' 
                        }}>
                            <div>
                                <article
                                css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px', borderBottom: '1px solid', borderBottomColor: menuList.borderColor, padding: '8px 12px' }}
                            ><span>device-name</span><Copy size={14} /></article>

                                <article
                                css={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px', borderBottom: '1px solid', borderBottomColor: menuList.borderColor, padding: '8px 12px' }}
                            ><span css={{marginRight: '32px', whiteSpace: 'nowrap', }}>device-name-that-is-very-long</span><Copy size={14} /></article>
                                <article
                                css={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px', borderBottom: '1px solid', borderBottomColor: menuList.borderColor, padding: '8px 12px' }}
                            ><span css={{marginRight: '32px', whiteSpace: 'nowrap', }}>mac-mini-server</span><Copy size={14} /></article>

                                <article
                                css={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px', padding: '8px 12px' }}
                            ><span>the-fourth-network-device</span><Copy size={14} /></article>
                            </div>
                            
                        </div>
                    </td>
                    <td css={{ borderColor: tableBorder }}>
                        <article css={{display: 'flex', justifyContent: 'left', alignItems: 'flex-start' }}>
                            <div css={{marginRight: '4px', marginTop: '2px' }}><ArrowUpCircle size={14} color={ upgradeArrow }/></div>
                            <div css={{ marginLeft: 0 }}>
                                <code css={{margin: 0}}>1.46.1</code><br />
                                <span css={{fontSize: '12px'}}>iOS 16.6.0</span>
                            </div>
                        </article>
                    </td>

                    <td css={{ borderColor: tableBorder, verticalAlign: 'middle', fontSize: '14px' }}>
                        <div css={{ display: 'inline-block', marginRight: '8px', borderRadius: '9999px', backgroundColor: theme.green[6], height: '8px', width: '8px'  }}></div><span>Connected</span>
                    </td>
                    <td css={{ borderColor: tableBorder, textAlign: 'right', paddingRight: '8px' }}>
                        <MoreHorizontal />
                    </td>

                </tr>
                <tr css={{ '&:hover': { backgroundColor: tableRowBackgroundHover} }}>
                    <td css={{
                        borderBottomColor: tableBorder,
                    }}>
                        <div>
                            <p css={{margin: 0, display:'flex', alignItems: 'center', gap: '8px' }}><span css={{display: 'block', width: '12px', height: '12px', borderRadius: '9999px', backgroundColor: theme.gray[randomInt(7,10)]}}></span><b>mels-iphone</b></p>
                            <p css={{ fontSize: '75%', margin: '4px 0 8px 0',  }}>1024threes@privaterelay.appleid.com</p>
        <div css={{display: 'inline-flex', gap: '8px' }}>
            <span css={{ padding: '2px 4px', borderRadius: '4px', fontSize: '12px', gap: '4px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap', color: tagBase.color, backgroundColor: tagBase.background, }}>Expiry disabled
            <AlertCircle size={12} color='currentColor' opacity={iconOpacity} />
        </span>

          <span css={{ padding: '2px 4px', borderRadius: '4px', fontSize: '12px', gap: '4px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap', color: tagPrimary.color, backgroundColor: tagPrimary.background, }}>Subnets <AlertCircle size={12} color='currentColor' opacity={iconOpacity}  /></span>
          <span css={{ padding: '2px 4px', borderRadius: '4px', fontSize: '12px', gap: '4px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap', color: tagPrimary.color, backgroundColor: tagPrimary.background, }}>Exit Node <AlertCircle size={12} color='currentColor' opacity={iconOpacity}  /></span>

          <span css={{ padding: '2px 4px', borderRadius: '4px', fontSize: '12px', gap: '4px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap', color: tagWarning.color, backgroundColor: tagWarning.background, }}>Warning Tag <AlertCircle size={12} color='currentColor' opacity={iconOpacity}  /></span>
      </div>
                        </div>
                    </td>
                    <td css={{ borderColor: tableBorder, position: 'relative' }}>
                        <div css={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <code>100.81.5.119</code>
                            <ChevronDown size={14} />
                        </div>
                    </td>
                    <td css={{ borderColor: tableBorder }}>

                        <div css={{display: 'flex'}}>
                        <div css={{visibility: 'hidden', marginRight: '4px', marginTop: '2px' }}><ArrowUpCircle size={14} color={upgradeArrow} /></div>
                        <div>
                            <code>1.48.4</code><br />
                            <span css={{fontSize: '12px'}}>iOS 16.6.0</span>
                        </div>
                    </div>
                    </td>
                    <td css={{ borderColor: tableBorder, verticalAlign: 'middle', fontSize: '14px' }}>
                        <div css={{ display: 'inline-block', marginRight: '8px', borderRadius: '9999px', backgroundColor: theme.gray[8], height: '8px', width: '8px'  }}></div><span>Feb 2, 8:48 PM PST</span>
                    </td>
                    <td css={{ borderColor: tableBorder, textAlign: 'right', paddingRight: '8px' }}>
                        <MoreHorizontal />
                    </td>

                </tr>
                <tr css={{ '&:hover': { backgroundColor: tableRowBackgroundHover} }}>
                    <td css={{
                        borderBottomColor: tableBorder,
                    }}>
                        <div>
                            <p css={{margin: 0, display:'flex', alignItems: 'center', gap: '8px' }}><span css={{ display: 'block', width: '12px', height: '12px', borderRadius: '9999px', backgroundColor: theme.red[5]}}></span><b>blakes-iphone</b></p>
                            <p css={{ fontSize: '75%', margin: '4px 0 8px 0',  }}>9998ptchasdf@privaterelay.appleid.com</p>

          <span css={{ padding: '2px 4px', marginRight: '8px', borderRadius: '4px', fontSize: '12px', gap: '4px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap', color: tagError.color, backgroundColor: tagError.background, }}>Setup Error <AlertCircle size={12} color='currentColor' opacity={iconOpacity} /></span>

          <span css={{ padding: '2px 4px', borderRadius: '4px', fontSize: '12px', gap: '4px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap', color: tagOutline.color, backgroundColor: 'transparent', boxShadow: 'inset 0 0 0 1px currentColor' }}>Enterprise  <AlertCircle size={12} color='currentColor' opacity={iconOpacity} /></span>
                        </div>
                    </td>
                    <td css={{ borderColor: tableBorder, position: 'relative' }}>
                        <div css={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <code>100.81.5.119</code>
                            <ChevronDown size={14} />
                        </div>
                        
                    </td>
                    <td css={{ borderColor: tableBorder }}>

                        <article css={{display: 'flex', justifyContent: 'left', alignItems: 'flex-start' }}>
                            <div css={{marginRight: '4px', marginTop: '2px' }}><ArrowUpCircle size={14} color={upgradeArrow} /></div>
                        <div>
                            <code>1.46.1</code><br />
                            <span css={{fontSize: '12px'}}>iOS 16.6.0</span>
                        </div>
                    </article>
                    </td>
                    <td css={{ borderColor: tableBorder, verticalAlign: 'middle', fontSize: '14px' }}>
                        <div css={{ display: 'inline-block', marginRight: '8px', borderRadius: '9999px', backgroundColor: theme.gray[8], height: '8px', width: '8px'  }}></div><span>Feb 2, 8:48 PM PST</span>
                    </td>
                    <td css={{ borderColor: tableBorder, textAlign: 'right', paddingRight: '8px' }}>
                        <MoreHorizontal />
                    </td>

                </tr>
<tr css={{ backgroundColor: tableRowBackgroundHover, }}>
                    <td css={{
                        borderBottomColor: tableBorder,
                    }}>
                        <div>
                            <p css={{margin: 0, display:'flex', alignItems: 'center', gap: '8px' }}>
                                <span css={{ display: 'block', width: '12px', height: '12px', borderRadius: '9999px', backgroundColor: theme.green[5]}}></span>
                                <b>an-anonymous-free-bsd--box</b>
                            </p>
                            <p css={{ fontSize: '75%', margin: '4px 0 0 0',  }}>9998ptchasdf@privaterelay.appleid.com</p>
                        </div>
                    </td>
                    <td css={{ borderColor: tableBorder, position: 'relative' }}>
                        <div css={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <code>192.61.8.121</code>
                            <ChevronDown size={14} />
                        </div>
                    </td>
                    <td css={{ borderColor: tableBorder }}>

                        <article css={{display: 'flex', justifyContent: 'left', alignItems: 'flex-start' }}>
                            <div css={{marginRight: '4px', marginTop: '2px' }}><ArrowUpCircle size={14} color={upgradeArrow} /></div>
                        <div>
                            <code>1.46.1</code><br />
                            <span css={{fontSize: '12px'}}>Free BSD 13.2.1</span>
                        </div>
                    </article>
                    </td>
                    <td css={{ borderColor: tableBorder, verticalAlign: 'middle', fontSize: '14px' }}>
                        <div css={{ display: 'inline-block', marginRight: '8px', borderRadius: '9999px', backgroundColor: theme.green[6], height: '8px', width: '8px'  }}></div><span>Connected</span>
                    </td>
                    <td css={{ borderColor: tableBorder, textAlign: 'right', paddingRight: '8px' }}>
                        <div css={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', borderRadius: '6px', borderWidth: '1px', borderStyle: 'solid', borderColor: tableBorder, paddingRight: '8px',
                        boxShadow:  '0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07)' 

                        }}>
                            <span css={{ padding: '4px 8px', fontSize: '14px', borderRightWidth: '1px', borderRightStyle: 'solid', borderRightColor: tableBorder, marginRight: '8px' }}>Share...</span>
                            <MoreHorizontal />
                        </div>
                    </td>

                </tr>
                <tr css={{ '&:hover': { backgroundColor: tableRowBackgroundHover} }}>
                    <td css={{
                        borderBottomColor: tableBorder,
                    }}>
                        <div>
                            <p css={{margin: 0, display:'flex', alignItems: 'center', gap: '8px' }}><span css={{display: 'block', width: '12px', height: '12px', borderRadius: '9999px', backgroundColor: theme.red[5]}}></span><b>second-anonymous-free-bsd-cpu-neural</b></p>
                            <p css={{ fontSize: '75%', margin: '4px 0 0 0',  }}>9998ptchasdf@privaterelay.appleid.com</p>
                        </div>
                    </td>
                    <td css={{ borderColor: tableBorder, position: 'relative' }}>
                        <div css={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <code>192.61.8.121</code>
                            <ChevronDown size={14} />
                        </div>
                    </td>
                    <td css={{ borderColor: tableBorder }}>

                        <article css={{display: 'flex', justifyContent: 'left', alignItems: 'flex-start' }}>
                            <div css={{marginRight: '4px', marginTop: '2px' }}><ArrowUpCircle size={14} color={upgradeArrow} /></div>
                        <div>
                            <code>1.46.1</code><br />
                            <span css={{fontSize: '12px'}}>Free BSD 13.2.1</span>
                        </div>
                    </article>
                    </td>
                    <td css={{ borderColor: tableBorder, verticalAlign: 'middle', fontSize: '14px' }}>
                        <div css={{ display: 'inline-block', marginRight: '8px', borderRadius: '9999px', backgroundColor: theme.green[6], height: '8px', width: '8px'  }}></div><span>Connected</span>
                    </td>
                    <td css={{ borderColor: tableBorder, textAlign: 'right', paddingRight: '8px' }}>
                        <MoreHorizontal />
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
    <section css={{paddingTop: '32px', paddingBottom: '32px' }}>
          
          <article css={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          borderWidth: '1px',
          borderColor: theme.gray[backgroundInt-1],
          borderStyle: 'solid',
          padding: '64px',
          borderRadius: '6px',
            boxShadow: 'rgba(0, 0, 0, .15) 0px 0px 0px 0px, rgba(0, 0, 0, .15) 0px 0px 0px 0px, rgba(0, 0, 0, 0.15) 0px 4px 12px 0px' 
          }}>

<svg xmlns="http://www.w3.org/2000/svg" width="1.125em" height="1.125em" viewBox="0 0 24 24" fill="none" stroke={theme.gray[6]}><g strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M8.3 10a.7.7 0 0 1-.626-1.079L11.4 3a.7.7 0 0 1 1.198-.043L16.3 8.9a.7.7 0 0 1-.572 1.1Z"></path><rect x="3" y="14" width="7" height="7" rx="1"></rect><circle cx="17.5" cy="17.5" r="3.5"></circle></g></svg>
            <h2 css={{ marginTop: '8px', marginBottom: '16px', fontWeight: 500, fontSize: '20px' }}>Add an app</h2>
            <p css={{ margin: 0, maxWidth: '58ch', lineHeight: 1.25, color: theme.gray[6] }}>Apps let you control access to your third-party SaaS applications, without requiring any end user configuration.</p>
            <div css={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
                
                <button css={{ padding: '8px 24px', 
                color: buttonPrimary.color, backgroundColor: buttonPrimary.background, borderColor: buttonPrimary.borderColor,
                '&:hover': { color: buttonPrimary.colorHover, backgroundColor: buttonPrimary.backgroundHover, borderColor: buttonPrimary.borderColorHover }
                }}>Primary</button>

          <button css={{ color: buttonPrimary.color, borderColor: 'transparent', backgroundColor: 'transparent!important', '&:hover': {backgroundColor: theme.blue[10] }}} css={{ padding: '8px 24px'  }}>Minimal Primary</button>
            </div>
        </article>

      </section>
      </section>

      </section>
      </header>
      <section css={{ gap: '24px', display: 'flex', flexDirection: 'column' }}>
<article css={{
         marginLeft: 'auto',
          borderRadius: '6px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: card.borderColor,
          overflow: 'hidden',
          maxWidth: '280px',
          minWidth: '256px',
          display: 'block',
          color: card.color,
          backgroundColor: card.background,
          boxShadow:'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 4px 12px 0px', 
          }}>
<div css={{ backgroundColor: 'rgb(0,0,0, .15)', aspectRatio: '5/2', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><svg width="36" height="36" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><circle opacity="0.2" cx="3.4" cy="3.25" r="2.7" fill="currentColor"></circle><circle cx="3.4" cy="11.3" r="2.7" fill="currentColor"></circle><circle opacity="0.2" cx="3.4" cy="19.5" r="2.7" fill="currentColor"></circle><circle cx="11.5" cy="11.3" r="2.7" fill="currentColor"></circle><circle cx="11.5" cy="19.5" r="2.7" fill="currentColor"></circle><circle opacity="0.2" cx="11.5" cy="3.25" r="2.7" fill="currentColor"></circle><circle opacity="0.2" cx="19.5" cy="3.25" r="2.7" fill="currentColor"></circle><circle cx="19.5" cy="11.3" r="2.7" fill="currentColor"></circle><circle opacity="0.2" cx="19.5" cy="19.5" r="2.7" fill="currentColor"></circle></svg></div>
          <hr css={{margin: 0, borderColor: card.borderColor, height: '1px', borderTopWidth: 0, borderBottomWidth: '1px', borderStyle: 'solid' }}/><div css={{color: card.color, padding: '0 16px 16px 16px'}}>
              <h3 css={{margin: '16px 0 4px 0',}}>Tailscale</h3>
              <p css={{margin: 0, fontSize: '14px'}}>Dark-mode of the future</p>
              <button onClick={() => updateAll()} css={{ 
              color: buttonSecondary.color, backgroundColor: buttonSecondary.background, borderColor: buttonSecondary.borderColor, 
              '&:hover': { color: buttonSecondary.colorHover, backgroundColor: buttonSecondary.backgroundHover, borderColor: buttonSecondary.borderColorHover }, 
              padding: '8px 24px', fontSize: '14px', marginTop: '20px', display: 'block', width: '100%', textAlign: 'center' }}>
                  <span>Regenerate for free</span>
              </button>
    </div></article>

    <article css={{ marginLeft: 'auto', minWidth: '256px', maxWidth: '280px', display: 'block', borderWidth: '1px', borderStyle: 'solid', borderColor: card.borderColor, background: card.background, color: card.color, borderRadius: '6px', padding: '20px' }}>
        <div>
            <div css={{textAlign: 'right' }}>
            <svg opacity={iconOpacity} width="45" height="30" viewBox="0 0 45 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-auto"><g clipPath="url(#clip0_10731_38920)"><path d="M30 30C38.2843 30 45 23.2843 45 15C45 6.71573 38.2843 0 30 0C21.7157 0 15 6.71573 15 15C15 23.2843 21.7157 30 30 30Z" fill="#3C7B5F"></path><path opacity="0.6" d="M15 0C6.717 0 0 6.717 0 15C0 23.283 6.717 30 15 30V0Z" fill="#3C7B5F"></path></g><defs><clipPath id="clip0_10731_38920"><rect width="45" height="30" fill="white"></rect></clipPath></defs></svg>
            </div>

            <div css={{ fontSize: '24px', }}>Free</div>
        </div>

    <div className="flex flex-col flex-1 items-start justify-between mt-2"><div><div className="flex items-end"><div css={{fontSize: '36px'}}>$0</div></div><p css={{ margin: 0}}>Up to 3 users</p></div>
        <div css={{ display: 'inline-flex', padding: '2px 12px', marginTop: '16px', fontSize: '12px', borderRadius: '9999px', background: 'rgb(255,255,255,.05)'
        }}>Your plan</div>
    </div></article>

    <article css={{ marginLeft: 'auto', minWidth: '256px', maxWidth: '280px', display: 'block', borderRadius: '6px', backgroundColor: card.background, padding: '20px', color: card.color, borderColor: card.borderColor, borderWidth: '1px', borderStyle: 'solid'  }}><div>
            <div css={{ textAlign: 'right'}}>
                <svg width="45" height="30" viewBox="0 0 45 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-auto"><g clipPath="url(#clip0_10731_38922)"><path d="M15.0004 30L45 30L45 0.000366211L15.0004 0.000366211L15.0004 30Z" fill="#476399"></path><path opacity="0.6" d="M0 0C8.2829 0 14.9998 6.71692 14.9998 14.9998C14.9998 23.2827 8.2829 29.9996 0 29.9996V0Z" fill="#476399"></path></g><defs><clipPath id="clip0_10731_38922"><rect width="45" height="30" fill="white"></rect></clipPath></defs></svg></div>
            <div css={{ fontSize: '24px' }}>Starter</div></div><div className="flex flex-wrap items-end gap-x-2 mt-2"><div css={{fontSize: '32px'}}>$6</div></div><p>Per active user/month</p><button css={{ width: '100%', borderRadius: '6px', 
            color: buttonSecondary.color, backgroundColor: buttonSecondary.background, borderColor: buttonSecondary.borderColor, padding: '8px 24px',
            '&:hover': { color: buttonSecondary.colorHover, backgroundColor: buttonSecondary.backgroundHover, borderColor: buttonSecondary.borderColorHover }, 

}} href="/admin/settings/billing/checkout/starter"><span>Upgrade to Starter</span></button></article>
</section>

      </section>
      
      
      
      <section css={{ padding: '32px', display: 'flex', gap: '16px', flexWrap: 'wrap'}}>
    <button css={{ 
    padding: '8px 24px', 
    color: buttonPrimary.color, 
    backgroundColor: buttonPrimary.background, 
    borderColor: buttonPrimary.borderColor,
    '&:hover': {
        color: buttonPrimary.colorHover, 
        backgroundColor: buttonPrimary.backgroundHover, 
        borderColor: buttonPrimary.borderColorHover,
    }
    }}>
    Primary
</button>
<button css={{ 
padding: '8px 24px', 
color: buttonSecondary.color, backgroundColor: buttonSecondary.background, borderColor: buttonSecondary.borderColor,
'&:hover': { color: buttonSecondary.colorHover, backgroundColor: buttonSecondary.backgroundHover, borderColor: buttonSecondary.borderColorHover }
}}>Secondary</button>
          <button css={{  padding: '8px 24px', 
          color: buttonWarning.color, backgroundColor: buttonWarning.background, borderColor: buttonWarning.borderColor,
          '&:hover': {
              color: buttonWarning.colorHover, backgroundColor: buttonWarning.backgroundHover, borderColor: buttonWarning.borderColorHover,
          }
          }}>Warning</button>
      <button css={{ 
      padding: '8px 24px', color: buttonError.color, backgroundColor: buttonError.background, borderColor: buttonError.borderColor,
      '&:hover': {
          color: buttonError.colorHover, backgroundColor: buttonError.backgroundHover, borderColor: buttonError.borderColorHover,
      }
      }}>Error</button>


          <button css={{ color: buttonPrimary.color, borderColor: 'transparent', backgroundColor: 'red', '&:hover': { backgroundColor: theme.blue[10] }}} css={{ padding: '8px 24px'  }}>Minimal Primary</button>
          <button css={{ backgroundColor: 'transparent', '&:hover': { backgroundColor: theme.gray[randomInt(10,11)]}}} css={{ padding: '8px 24px', color: appTheme[appThemeInt].color, borderColor: 'transparent' }}>Minimal Secondary</button>
          <button css={{  color: buttonWarning.color, backgroundColor: 'transparent', borderColor: 'transparent', '&:hover': {background: theme.yellow[10]} }} css={{ padding: '8px 24px' }}>Minimal Warning</button>
          <button css={{ padding: '8px 24px', color: buttonError.color, backgroundColor: 'transparent', borderColor: 'transparent', '&:hover': { backgroundColor: theme.red[randomInt(9,10)] } }}>Minimal Error</button>

          <button css={{ opacity: .5, padding: '8px 24px', color: buttonPrimary.color, backgroundColor: buttonPrimary.background, borderColor: buttonPrimary.borderColor }}>Primary</button>
          <button  css={{ opacity: .5, padding: '8px 24px', color: buttonSecondary.color, backgroundColor: buttonSecondary.background, borderColor: buttonSecondary.borderColor }}>Secondary</button>
          <button css={{  opacity: .5, padding: '8px 24px', color: buttonWarning.color, backgroundColor: buttonWarning.background, borderColor: buttonWarning.borderColor }}>Warning</button>
          <button  css={{ opacity: .5, padding: '8px 24px', color: buttonError.color, backgroundColor: buttonError.background, borderColor: buttonError.borderColor }}>Error</button>
<div css={{display: 'flex', alignItems: 'center', gap: '16px' }}>
                  <input id="my-toggle2" type="checkbox" className="toggle" defaultChecked css={{ backgroundColor: buttonPrimary.background }} />
                  <input id="my-toggle" type="checkbox" className="toggle" css={{ backgroundColor: theme.gray[backgroundInt-2]}} />

          <span css={{ padding: '2px 4px', borderRadius: '4px', fontSize: '12px', gap: '4px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap', color: tagBase.color, backgroundColor: tagBase.background, }}>Base Tag <AlertCircle size={12} color='currentColor' opacity={iconOpacity} /></span>
          <span css={{ padding: '2px 4px', borderRadius: '4px', fontSize: '12px', gap: '4px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap', color: tagPrimary.color, backgroundColor: tagPrimary.background, }}>Primary Tag <AlertCircle size={12} color='currentColor' opacity={iconOpacity} /></span>
          <span css={{ padding: '2px 4px', borderRadius: '4px', fontSize: '12px', gap: '4px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap', color: tagSuccess.color, backgroundColor: tagSuccess.background, }}>Success Tag <AlertCircle size={12} color='currentColor' opacity={iconOpacity} /></span>
          <span css={{ padding: '2px 4px', borderRadius: '4px', fontSize: '12px', gap: '4px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap', color: tagWarning.color, backgroundColor: tagWarning.background, }}>Warning Tag <AlertCircle size={12} color='currentColor' opacity={iconOpacity}  /></span>
          <span css={{ padding: '2px 4px', borderRadius: '4px', fontSize: '12px', gap: '4px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap', color: tagError.color, backgroundColor: tagError.background, }}>Error Tag <AlertCircle size={12} color='currentColor' opacity={iconOpacity} /></span>
          <span css={{ padding: '2px 4px', borderRadius: '4px', fontSize: '12px', gap: '4px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap', color: appTheme[appThemeInt].color, backgroundColor: appTheme.background, boxShadow: 'inset 0 0 0 1px currentColor' }}>Outline Tag <AlertCircle size={12} color='currentColor' opacity={iconOpacity} /></span>
      </div>
      </section>
      
      <section css={{padding: '32px'}}>
          <article css={{display: 'block', borderRadius: '6px', 
          background: noteError.background, 
          color: noteError.color, 
          borderColor: noteError.borderColor, 
          borderWidth: '1px', borderStyle: 'solid', padding: '16px'}}>
            <h4 css={{display: 'flex', fontWeight: 700, alignItems: 'center', gap: '8px', margin: 0, }}><AlertCircle size={16} />This is a note with a title</h4>
            <p css={{ marginTop: '4px', paddingLeft: '24px', fontSize: '14px', lineHeight: 1.5, marginBottom: 0 }}>
                Sometimes you have a note so important that it needs a title of its own. Use a title when you can distill the main message in one sentence, and then use the note’s body text to provide more details.
            </p>

        </article>
        <article css={{ marginTop: '16px', display: 'block', borderRadius: '6px', 
        background: noteWarning.background, 
        color: noteWarning.color, 
        borderColor: noteWarning.borderColor, 
        borderWidth: '1px', borderStyle: 'solid', padding: '16px'}}>
            <h4 css={{display: 'flex', fontWeight: 700, alignItems: 'center', gap: '8px', margin: 0, }}><AlertCircle size={16} />This is a note with a title</h4>
            <p css={{ marginTop: '4px', paddingLeft: '24px', fontSize: '14px', lineHeight: 1.5, marginBottom: 0 }}>
                Sometimes you have a note so important that it needs a title of its own. Use a title when you can distill the main message in one sentence, and then use the note’s body text to provide more details.
            </p>

        </article>
        <article css={{ marginTop: '16px', display: 'block', borderRadius: '6px', 
        background: noteBase.background, 
        color: noteBase.color, 
        borderColor: noteBase.borderColor, 
        borderWidth: '1px', borderStyle: 'solid', padding: '16px'}}>
            <h4 css={{display: 'flex', fontWeight: 700, alignItems: 'center', gap: '8px', margin: 0, }}><AlertCircle size={16} />This is a note with a title</h4>
            <p css={{ marginTop: '4px', paddingLeft: '24px', fontSize: '14px', lineHeight: 1.5, marginBottom: 0 }}>
                Sometimes you have a note so important that it needs a title of its own. Use a title when you can distill the main message in one sentence, and then use the note’s body text to provide more details.
            </p>

        </article>
        <article css={{ marginTop: '16px', display: 'block', borderRadius: '6px', 
        background: notePrimary.background, 
        color: notePrimary.color, 
        borderColor: notePrimary.borderColor, 
        borderWidth: '1px', borderStyle: 'solid', padding: '16px'}}>
            <h4 css={{display: 'flex', fontWeight: 700, alignItems: 'center', gap: '8px', margin: 0, }}><AlertCircle size={16} />This is a note with a title</h4>
            <p css={{ marginTop: '4px', paddingLeft: '24px', fontSize: '14px', lineHeight: 1.5, marginBottom: 0 }}>
                Sometimes you have a note so important that it needs a title of its own. Use a title when you can distill the main message in one sentence, and then use the note’s body text to provide more details.
            </p>

        </article>
      </section>
      <footer css={{borderTopWidth: '1px', borderTopStyle: 'solid', borderTopColor: appTheme.borderColor }}>

<section css={{padding: '32px', marginTop: '32px', fontSize: '12px', display: 'flex', alignItems: 'center', gap: '32px' }}>
          <label css={{display: 'inline-flex', gap: '4px', whiteSpace: 'nowrap'  }}>
              <input onClick={() => setTheme(theme1)} type='radio' name='theme' />
              <span>Theme 1</span>
          </label>
          <label css={{display: 'inline-flex', gap: '4px', whiteSpace: 'nowrap' }}>
              <input onClick={() => setTheme(theme2)} type='radio' name='theme'/>
              <span>Theme 2</span>
          </label>

              <button onClick={() => updateAll()} css={{ color: buttonSecondary.color, backgroundColor: buttonSecondary.background, borderColor: buttonSecondary.borderColor, padding: '6px 8px', fontSize: '14px',  display: 'block', width: '100%', textAlign: 'center' }}>
                  <span>Dark Mode</span>
              </button>

              <button onClick={() => randomAll()} css={{ color: buttonSecondary.color, backgroundColor: buttonSecondary.background, borderColor: buttonSecondary.borderColor, padding: '6px 8px', fontSize: '14px',  display: 'block', width: '100%', textAlign: 'center' }}>
                  <span>Random</span>
              </button>
              <button onClick={() => optionA()} css={{ whiteSpace: 'nowrap', marginLeft: 'auto', lineHeight: 1, padding: '8px 24px', 
                color: buttonPrimary.color, backgroundColor: buttonPrimary.background, borderColor: buttonPrimary.borderColor,
                '&:hover': { color: buttonPrimary.colorHover, backgroundColor: buttonPrimary.backgroundHover, borderColor: buttonPrimary.borderColorHover }
                }}>A</button>
              <button onClick={() => optionB()} css={{ whiteSpace: 'nowrap', marginLeft: 'auto', lineHeight: 1, padding: '8px 24px', 
                color: buttonPrimary.color, backgroundColor: buttonPrimary.background, borderColor: buttonPrimary.borderColor,
                '&:hover': { color: buttonPrimary.colorHover, backgroundColor: buttonPrimary.backgroundHover, borderColor: buttonPrimary.borderColorHover }
                }}>B</button>
              <button onClick={() => optionC()} css={{ whiteSpace: 'nowrap', marginLeft: 'auto', lineHeight: 1, padding: '8px 24px', 
                color: buttonPrimary.color, backgroundColor: buttonPrimary.background, borderColor: buttonPrimary.borderColor,
                '&:hover': { color: buttonPrimary.colorHover, backgroundColor: buttonPrimary.backgroundHover, borderColor: buttonPrimary.borderColorHover }
                }}>C</button>
      </section>
          <dl css={{margin: 0, padding: '16px 32px', display: 'flex', justifyContent: 'center', gap: '8px', fontSize: '12px', fontFamily: 'monospace' }}><dt>Generated designs</dt><dd>{count}</dd></dl>
      </footer>
    </div>
    </div>
  );
}

export default App;
